import React from "react"
import { MoneyInputContainer } from "./components"

interface Props {
  value: string
  valueMaxLength: number
  setValue: (name: string) => void

  iconCode: string
  label: string
  placeholder: string

  error?: boolean
}

export const TextInput: React.FC<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  value,
  valueMaxLength,
  setValue,
  iconCode,
  label,
  placeholder,
  error,
  ...props
}) => {
  return (
    <MoneyInputContainer
      inputAtLimit={value.length === valueMaxLength}
      icon={iconCode}
      label={label}
      error={error}
    >
      <input
        {...props}
        value={value}
        maxLength={valueMaxLength}
        placeholder={placeholder}
        onChange={(e) => setValue(e.currentTarget.value)}
      ></input>
      <span>
        {value.length}/{valueMaxLength}
      </span>
    </MoneyInputContainer>
  )
}

export const TextArea: React.FC<
  Props & React.InputHTMLAttributes<HTMLTextAreaElement>
> = ({
  value,
  valueMaxLength,
  setValue,
  iconCode,
  label,
  placeholder,
  error,
  ...props
}) => {
  return (
    <MoneyInputContainer
      inputAtLimit={value.length === valueMaxLength}
      icon={iconCode}
      label={label}
      error={error}
    >
      <textarea
        {...props}
        rows={8}
        value={value}
        maxLength={valueMaxLength}
        placeholder={placeholder}
        onChange={(e) => setValue(e.currentTarget.value)}
      ></textarea>
      <span>
        {value.length}/{valueMaxLength}
      </span>
    </MoneyInputContainer>
  )
}
