import React from "react"
import ReactDOM from "react-dom"
import { ThemeProvider } from "styled-components/macro"
import App from "./App"
import "./index.css"
import { DataProvider } from "./providers/data"
import { PWAProvider } from "./PWAProvider"
import reportWebVitals from "./reportWebVitals"
import "./reset.css"
import { myTheme } from "./theme"

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={myTheme}>
      <PWAProvider>
        <DataProvider>
          <App />
        </DataProvider>
      </PWAProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
