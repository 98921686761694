import styled from "styled-components/macro"

export const Container = styled.div`
  height: 100%;
  display: grid;
`

export const Main = styled.main<{ extraDark?: boolean }>`
  transition: all ease-in-out 0.3s;
  background: ${(props) =>
    props.extraDark
      ? props.theme.colors.darkbackground
      : props.theme.colors.background};
  padding: ${(props) => props.theme.paddings.lg};
  padding-top: ${(props) => props.theme.paddings.xl};
  max-height: calc(100%);
  overflow-y: scroll;
`

export const Footer = styled.footer`
  border-top: ${(props) => `solid 1px ${props.theme.colors.transparent}`};
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`
