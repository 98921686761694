import React from "react"
import { NavLink } from "react-router-dom"
import { StyledButton } from "../../components/Button"
import { GoalGroup } from "../../domainComponents/GoalGroup"
import { useData } from "../../providers/data"

export const View: React.FC = () => {
  const { goals } = useData()
  return (
    <div className="d-flex flex-column justify-content-between align-items-center h-100">
      <div
        className="d-flex flex-column justify-content-start h-100"
        style={{
          overflowY: "scroll",
        }}
      >
        <GoalGroup type="year" />
        <GoalGroup type="month" />
        <GoalGroup type="week" />
        <div style={{ width: "100%", minHeight: "8rem" }} />
      </div>

      <NavLink
        style={{
          position: "absolute",
          bottom: "2rem",
          right: "2rem",
        }}
        activeClassName="navLink-active"
        to="/add"
      >
        <StyledButton
          clickEventTitle="actionButtonClick"
          clickEventProperties={{
            type: "addgoal",
          }}
          // FIXME: Maybe use classnames package to pass these in as an array?
          color={"semitransparent"}
          className={goals.length === 0 ? "clickme" : ""}
        >
          Add Goal
        </StyledButton>
      </NavLink>
    </div>
  )
}
