import React from "react"
import "./App.css"

import { StyledButton } from "./components/Button"
import styled from "styled-components"
import App from "./App"
import { Icon, InlineTitle, Title } from "./components/typography"
import { useData } from "./providers/data"

export const WelcomeDiv = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`

export const Welcome: React.FC = () => {
  const { setFirstTime } = useData()
  return (
    <WelcomeDiv>
      <div className="my-4 d-flex align-items-center w-100 justify-content-center">
        <InlineTitle size="xl" weight={800} color="cyan">
          YEAR
        </InlineTitle>
        <Icon color="transparent" size="xxs" className="mx-1 fas fa-circle" />
        <InlineTitle size="xl" weight={800} color="yellow">
          MONTH
        </InlineTitle>

        <Icon color="transparent" size="xxs" className="mx-1 fas fa-circle" />
        <InlineTitle size="xl" weight={800} color="pink">
          WEEK
        </InlineTitle>
      </div>
      <Title weight={300} className="py-1 px-4" color="white">
        The goal setting app that is based on it's namesake; keep your sights
        set on your big <InlineTitle color="cyan">yearly</InlineTitle> goals
        while you periodically set{" "}
        <InlineTitle color="yellow">monthly</InlineTitle> and{" "}
        <InlineTitle color="pink">weekly</InlineTitle> goals to keep you
        accountable. The app is barebones and simple as it should be - focus on
        achieiving your goals instead of just talking about them.
      </Title>

      <StyledButton
        clickEventTitle="actionButtonClick"
        clickEventProperties={{
          type: "start",
        }}
        color={"semitransparent"}
        onClick={() => {
          setFirstTime(false)
        }}
        className={"clickme"}
        style={{ alignSelf: "center" }}
      >
        Start
      </StyledButton>
    </WelcomeDiv>
  )
}

export default App
