import React, { useState } from "react"

import { TextArea, TextInput } from "../../components/Input/index"
import { GoalType } from "../../api/interfaces"
import {
  getColorFromType,
  getDifferenceBetweenDates,
  getDueDateFromType,
  getFormattedDate,
} from "../../utilities"
import { StyledButton } from "../../components/Button"
import { useData } from "../../providers/data"
import { InlineTitle } from "../../components/typography"
import { useHistory } from "react-router-dom"
import { RadioButton } from "../../components/RadioButton"
import { AddPageContainer } from "./components"

export const Add: React.FC = () => {
  const { addGoal, addingGoal, clearAddGoalError } = useData()

  const [missingName, setMissingName] = useState<boolean>(false)
  const [missingMotivation, setMissingMotivation] = useState<boolean>(false)

  const [setForFuture, setSetForFuture] = useState<boolean>(false)

  // Local State values for current goal being created
  const [name, setName] = useState("")
  const [motivation, setMotivation] = useState("")
  const [type, setType] = useState<GoalType>("year")

  const history = useHistory()

  const color = getColorFromType(type)

  const dueDate = getDueDateFromType(type, setForFuture)
  const daysLeft = getDifferenceBetweenDates(dueDate, new Date())

  const nameMaxLength = 30
  const motivationMaxLength = 200

  return (
    <AddPageContainer>
      <div className="d-flex flex-column">
        <TextInput
          value={name}
          setValue={setName}
          valueMaxLength={nameMaxLength}
          iconCode="f02b"
          label="Name"
          placeholder=""
          error={missingName}
          onFocus={() => setMissingName(false)}
          className={missingName ? "error" : ""}
        />

        <TextArea
          value={motivation}
          setValue={setMotivation}
          valueMaxLength={motivationMaxLength}
          iconCode="f672"
          label="Why is this important to you"
          placeholder=""
          error={missingMotivation}
          onFocus={() => setMissingMotivation(false)}
        />
      </div>

      <div className="d-flex flex-column w-100 justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <form className="d-flex flex-row justify-content-between w-100 align-items-center">
            <RadioButton
              color="cyan"
              checked={type === "year"}
              onChange={(e) => setType("year")}
              id="year"
              label="YEAR"
              clickEventTitle="goalTypeClick"
              clickEventProperties={{
                type: "year",
                nameIsFilledIin: name.length !== 0,
                motivationIsFilledIn: motivation.length !== 0,
              }}
            />
            <RadioButton
              color="yellow"
              checked={type === "month"}
              onChange={(e) => setType("month")}
              id="month"
              label="MONTH"
              clickEventTitle="goalTypeClick"
              clickEventProperties={{
                type: "month",
                nameIsFilledIin: name.length !== 0,
                motivationIsFilledIn: motivation.length !== 0,
              }}
            />
            <RadioButton
              color="pink"
              checked={type === "week"}
              onChange={(e) => setType("week")}
              id="week"
              label="WEEK"
              clickEventTitle="goalTypeClick"
              clickEventProperties={{
                type: "week",
                nameIsFilledIin: name.length !== 0,
                motivationIsFilledIn: motivation.length !== 0,
              }}
            />
          </form>
        </div>

        <div className="d-flex flex-row  justify-content-between">
          <InlineTitle size="md" color="transparent">
            {getFormattedDate(getDueDateFromType(type, setForFuture))}
          </InlineTitle>
          <div className="d-flex align-items-center">
            <InlineTitle size="md" className="mx-2" color={color}>
              {daysLeft}
            </InlineTitle>
            <InlineTitle size="md" color="transparent">
              days left
            </InlineTitle>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-around">
        <RadioButton
          color={color}
          checked={!setForFuture}
          onChange={(e) => setSetForFuture(false)}
          id="setForFutureFalse"
          label={`This ${type}`}
          clickEventTitle="goalTypeClick"
          clickEventProperties={{
            type: "year",
            nameIsFilledIin: name.length !== 0,
            motivationIsFilledIn: motivation.length !== 0,
          }}
        />
        <RadioButton
          color={color}
          checked={setForFuture}
          onChange={(e) => setSetForFuture(true)}
          label={`Next ${type}`}
          id="setForFutureTrue"
          clickEventTitle="goalTypeClick"
          clickEventProperties={{
            type: "month",
            nameIsFilledIin: name.length !== 0,
            motivationIsFilledIn: motivation.length !== 0,
          }}
        />
      </div>
      <div>
        <StyledButton
          clickEventTitle="actionButtonClick"
          clickEventProperties={{
            type: "cancel",
            nameIsFilledIin: name.length !== 0,
            motivationIsFilledIn: motivation.length !== 0,
          }}
          style={{
            position: "absolute",
            bottom: "2rem",
            left: "2rem",
          }}
          onClick={() => {
            clearAddGoalError()
            history.push("/")
          }}
          color={"semitransparent"}
        >
          Cancel
        </StyledButton>
        <StyledButton
          clickEventTitle="actionButtonClick"
          clickEventProperties={{
            type: "save",
            nameIsFilledIin: name.length !== 0,
            motivationIsFilledIn: motivation.length !== 0,
          }}
          style={{
            position: "absolute",
            bottom: "2rem",
            right: "2rem",
          }}
          onClick={async () => {
            if (!name) {
              setMissingName(true)
              return
            }
            if (!motivation) {
              setMissingMotivation(true)
              return
            }
            const success = await addGoal({
              name,
              type,
              motivation,
              dateCreated: new Date(),
              dueDate: getDueDateFromType(type, setForFuture),
            })
            if (success) {
              clearAddGoalError()
              history.push("/")
            }
          }}
          // FIXME: Maybe use classnames package to pass these in as an array?
          className={`align-self-center ${addingGoal ? "loading" : ""}`}
          color={color}
        >
          Save
        </StyledButton>
      </div>
    </AddPageContainer>
  )
}
