import React, { useEffect, useState } from "react"
import * as serviceWorkerRegistration from "../serviceWorkerRegistration"

interface PWAProps {
  updateAvailable: boolean
  isOffline: boolean
}

const PWAContext = React.createContext<PWAProps>({
  isOffline: false,
  updateAvailable: false,
})
export const usePWA = () => React.useContext(PWAContext)

export const PWAProvider: React.FC = ({ children }) => {
  const [updateAvailable, setUpdateAvailable] = useState(false)
  const [isOffline, setIsOffline] = useState(false)

  useEffect(() => {
    const handleOnlineOfflineEvents = () => {
      if (navigator.onLine) {
        setIsOffline(false)
      } else {
        setIsOffline(true)
      }
    }
    handleOnlineOfflineEvents()
    window.addEventListener("offline", handleOnlineOfflineEvents)
    window.addEventListener("online", handleOnlineOfflineEvents)
    serviceWorkerRegistration?.register({
      onUpdate: (r) => {
        r.waiting?.postMessage({ type: "SKIP_WAITING" })
        // ♻️ To automatically refresh when there is an update available,
        // add a window.location.reload() here instead of setting flag
        setIsOffline(false)
        setUpdateAvailable(true)
      },
      onFailedUpdate: () => {
        setIsOffline(true)
      },
      onSuccessUpdate: () => {
        setIsOffline(false)
      },
    })
    window?.navigator?.serviceWorker?.ready?.then((r) => {
      r.waiting?.postMessage({ type: "SKIP_WAITING" })
      r.active?.postMessage({ type: "SKIP_WAITING" })
    })
  }, [])
  return (
    <PWAContext.Provider value={{ isOffline, updateAvailable }}>
      {children}
    </PWAContext.Provider>
  )
}
