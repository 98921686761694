import React, { useState } from "react"
import { GoalType } from "../api/interfaces"
import {
  Banner,
  DenseTitle,
  Icon,
  InlineTitle,
  SmallTitle,
} from "../components/typography"
import { useData } from "../providers/data"
import {
  getColorFromType,
  getDifferenceBetweenDates,
  getDueDateFromType,
} from "../utilities"
import { GoalComponent } from "./Goal"

import moment from "moment"
import { animated, config, useSpring } from "react-spring"
import useMeasure from "react-use-measure"
import { trackClick } from "../components/analytics"
import { DaysLeftBit, PageContainer } from "./components"

interface Props {
  type: GoalType
}

export const GoalGroup: React.FC<Props> = ({ type }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [measureRef, { height }] = useMeasure()

  const styles = useSpring({
    config: config.default,
    from: {
      height: 0,
    },
    to: {
      height: open ? height : 0,
    },
  })

  const { goals, goalMarkedForCompletion } = useData()

  const color = getColorFromType(type)
  const filteredGoals = goals.filter((g) => g.type === type)

  const goalCount = filteredGoals.length

  const currentDueDate = getDueDateFromType(type, false)
  const followingDueDate = getDueDateFromType(type, true)
  const currentDaysLeft = getDifferenceBetweenDates(currentDueDate, new Date())
  const followingDaysLeft = getDifferenceBetweenDates(
    followingDueDate,
    new Date()
  )

  console.log("currentDueDate", currentDueDate)
  console.log("followingDueDate", followingDueDate)

  console.log("filteredGoals", filteredGoals)
  const goalsForCurrentPeriod = filteredGoals.filter((g) =>
    moment(g.dueDate).isSame(currentDueDate)
  )
  const goalsForNextPeriod = filteredGoals.filter((g) =>
    moment(g.dueDate).isSame(followingDueDate)
  )

  const goalsInThePast = filteredGoals.filter((g) =>
    moment(g.dueDate).isBefore(new Date())
  )

  const completedCount = goalsForCurrentPeriod.filter((g) => !!g.dateCompleted)
    .length

  return (
    <PageContainer>
      <div
        className="my-1 d-flex flex-column align-items-start"
        style={{
          transition: "all ease-in-out .3s",
          filter: goalMarkedForCompletion ? "grayscale(1)" : "",
        }}
      >
        <div
          onClick={() => {
            if (!open) {
              trackClick("goalGroupOpen", {
                type,
                groupHasGoals: goalCount !== 0,
              })
            }
            setOpen(!open)
          }}
          className="d-flex align-items-center justify-content-between w-100"
        >
          <div className="d-flex align-items-center">
            <Banner className="my-0" color={color}>
              {type.toUpperCase()}
            </Banner>
            <InlineTitle size="sm" className="mx-1">
              ( {completedCount} of{" "}
              <InlineTitle size="sm" color={color}>
                {goalCount}{" "}
              </InlineTitle>
              met )
            </InlineTitle>
          </div>
          <Icon
            color={color}
            className="my-0 fal fa-chevron-right mx-4"
            style={{
              transition: "all ease-in-out .3s",
              transform: `rotate(${open ? "90deg" : "0deg"})`,
            }}
          />
        </div>
      </div>
      <animated.div style={{ overflow: "hidden", ...styles }}>
        <div ref={measureRef}>
          {!goalsForCurrentPeriod.length && !goalsForNextPeriod.length ? (
            <Banner color="transparent" size="xl">
              No {type} goals yet
            </Banner>
          ) : null}
          {goalsForCurrentPeriod.length ? (
            <>
              <div className="d-flex flex-row align-items-center w-100 justify-content-between">
                <Banner color="transparent" size="lg">
                  This {type}
                </Banner>

                <DaysLeftBit>
                  <DenseTitle color={color} className="my-0">
                    {currentDaysLeft}
                  </DenseTitle>
                  <SmallTitle className="mx-1">days left</SmallTitle>
                </DaysLeftBit>
              </div>
              {goalsForCurrentPeriod.map((goal) => (
                <GoalComponent key={goal.name + goal.type} goal={goal} />
              ))}
            </>
          ) : null}
          {goalsForNextPeriod.length ? (
            <>
              <div className="d-flex flex-row align-items-center w-100 justify-content-between">
                <Banner color="transparent" size="lg">
                  Next {type}
                </Banner>

                <DaysLeftBit>
                  <DenseTitle color={color} className="my-0">
                    {followingDaysLeft}
                  </DenseTitle>
                  <SmallTitle className="mx-1">days left</SmallTitle>
                </DaysLeftBit>
              </div>

              {goalsForNextPeriod.map((goal) => (
                <GoalComponent key={goal.name + goal.type} goal={goal} />
              ))}
            </>
          ) : null}
          {goalsInThePast.length ? (
            <>
              <div className="d-flex flex-row align-items-center w-100 justify-content-between">
                <Banner color="transparent" size="lg">
                  Past {type}s
                </Banner>
              </div>

              {goalsInThePast.map((goal) => (
                <GoalComponent key={goal.name + goal.type} goal={goal} />
              ))}
            </>
          ) : null}
        </div>
      </animated.div>
    </PageContainer>
  )
}
