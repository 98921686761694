import styled from "styled-components/macro"
import { myTheme } from "../../theme"

interface StyledRadioDivProps {
  color: keyof typeof myTheme["colors"]
}

export const RadioDiv = styled.div<StyledRadioDivProps>`
  input[type="radio"] {
    display: none;
    -webkit-appearance: none;
  }
  label {
    -webkit-appearance: none;
    user-select: none;
    display: inline-block;

    background-color: transparent;
    padding: 0.25rem 0.75rem;

    font-family: ${(props) => props.theme.fonts.family.primary};
    font-size: ${(props) => props.theme.fonts.sizes.sm};
    font-weight: 800;
    text-transform: capitalize;

    cursor: pointer;
    color: ${(props) => props.theme.colors.white};

    border-style: solid;
    border-width: 2px;
    border-color: ${(props) => props.theme.colors.transparent};
    border-radius: 100px;

    margin-top: 8px;
    margin-bottom: 8px;
  }

  input[type="radio"]:checked + label {
    transition: all ease-in-out 0.3s;
    border: ${(props) => `2px solid ${props.theme.colors[props.color]}`};
    color: ${(props) => props.theme.colors[props.color]};
  }
`
