import { usePWA } from "../PWAProvider"
import { LightIndicator } from "./PWAcomponents"

export const StatusLight = () => {
  const { isOffline } = usePWA()
  const color = isOffline ? "#FF645C" : "#85FFA7"
  const label = isOffline ? "offline" : "online"
  return (
    <LightIndicator color={color}>
      <label>{label}</label>
    </LightIndicator>
  )
}
