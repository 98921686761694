// my-theme.ts
export const darkColors = {
  white: "white",
  background: "#3b4b5a",
  mediumbackground: "#5c6c7d",
  darkbackground: "#1c2b39",
  cyan: "#00EED7",
  yellow: "#FFD600",
  pink: "#FF5BA1",
  error: "#ff7e7e",
  transparent: "rgba(255,255,255, .3)",
  semitransparent: "rgba(255,255,255, .65)",
}

export const lightColors = {
  white: "white",
  semitransparent: "rgba(255,255,255, .65)",
  transparent: "rgba(255,255,255, .3)",
  mediumbackground: "#5c6c7d",
  background: "#3b4b5a",
  darkbackground: "#1c2b39",
  cyan: "#51B2C6",
  pink: "#CE4E84",
  yellow: "#FFD600",
  error: "#ff7e7e",
}

export const colorPalletes = {
  dark: darkColors,
  light: lightColors,
}

export const myTheme = {
  borderRadius: "5px",

  paddings: {
    sm: ".25rem",
    md: ".5rem",
    lg: "1rem",
    xl: "2rem",
  },

  sizes: {
    banner: "3rem",
    large: "2rem",
    paragraph: "1rem",
  },
  colors: darkColors,
  fonts: {
    family: {
      primary: "Jost, sans-serif;",
    },
    weights: {
      100: 100,
      200: 200,
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
      900: 900,
    },
    sizes: {
      xxxs: ".625rem",
      xxs: ".75rem",
      xs: ".875rem",
      sm: "1rem",
      smm: "1.125rem",
      md: "1.25rem",
      lg: "1.5rem",
      xl: "2rem",
      xxl: "2.5rem",
      xxxl: "8rem",
    },
  },
  footer: {
    buttonDiameter: 3.5, // in REM (left as number for JS calculations)
    buttonBorderRadius: "3px", // in REM (left as number for JS calculations)
    indicatorWidth: 3, // in REM (left as number for JS calculations)
    indicatorHeight: "3px", // in REM (left as number for JS calculations)
  },
}

export type ThemeType = typeof myTheme
