import React, { useState } from "react"
import { Goal } from "../api/interfaces"
import { getColorFromType, getFormattedDate } from "../utilities"
import { Icon, InlineTitle, SmallTitle, Title } from "../components/typography"
import {
  GoalContainer,
  FirstGoalRow,
  SecondGoalRow,
  CheckBox,
  IconContainer,
  CheckBoxContainer,
} from "./components"
import { useData } from "../providers/data"

import confetti from "canvas-confetti"
import { trackClick } from "../components/analytics"

interface Props {
  goal: Goal
}

const blastConfetti = () => {
  const end = Date.now() + 1 * 600
  const colors = ["#FFD600", "#00EED7", "#FF5BA1"]

  ;(function frame() {
    confetti({
      particleCount: 3,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: colors,
    })
    confetti({
      particleCount: 3,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: colors,
    })

    if (Date.now() < end) {
      requestAnimationFrame(frame)
    }
  })()
}

export const GoalComponent: React.FC<Props> = ({ goal }) => {
  const { name, type, motivation } = goal
  const [toDelete, setToDelete] = useState<boolean>(false)
  const color = getColorFromType(type)
  const {
    removeGoal,
    finishGoal,
    goalMarkedForCompletion,
    setGoalMarkedForCompletion,
    revertGoal,
  } = useData()

  const currentGoalIsMarkedForCompletion =
    goal.name === goalMarkedForCompletion?.name &&
    goal.type === goalMarkedForCompletion.type
  return (
    <GoalContainer
      style={{
        transition: "all ease-in-out .3s",
        filter:
          goalMarkedForCompletion && !currentGoalIsMarkedForCompletion
            ? "grayscale(1)"
            : "",
        opacity: goal.dateCompleted ? 0.65 : 1,
      }}
    >
      <FirstGoalRow>
        <div className="d-flex align-items-center">
          <CheckBoxContainer
            onClick={() => {
              if (goal.dateCompleted) {
                trackClick("goalInteraction", { type: "revert" })
                revertGoal(goal)
                return
              }
              if (!goalMarkedForCompletion) {
                trackClick("goalInteraction", { type: "set_for_completion" })
                setGoalMarkedForCompletion(goal)
              } else if (currentGoalIsMarkedForCompletion) {
                trackClick("goalInteraction", { type: "complete" })
                blastConfetti()
                finishGoal(goal)
                setGoalMarkedForCompletion(null)
              } else {
                trackClick("goalInteraction", { type: "set_for_completion" })
                setGoalMarkedForCompletion(goal)
              }
            }}
          >
            <CheckBox
              checked={!!goal.dateCompleted}
              className={currentGoalIsMarkedForCompletion ? "tocomplete" : ""}
              color={color}
              type="checkbox"
            />
            {currentGoalIsMarkedForCompletion && (
              <Title size="sm" className="tocomplete">
                complete
              </Title>
            )}
          </CheckBoxContainer>
          <Title
            style={{ filter: "unset" }}
            className={
              toDelete
                ? "crossed-out"
                : currentGoalIsMarkedForCompletion
                ? "tocomplete"
                : ""
            }
            type={type}
            size="md"
          >
            {name}
          </Title>
        </div>

        <div
          style={{ marginLeft: ".5rem" }}
          className="d-flex align-items-center"
        >
          <Icon
            onClick={() => {
              trackClick("goalInteraction", { type: "unset_from_delete" })
              setToDelete(false)
            }}
            size="lg"
            color="white"
            className={` ${toDelete ? "icon-appear" : "icon-hide"} fas fa-undo`}
          />
          <IconContainer>
            <Icon
              onClick={() => {
                trackClick("goalInteraction", { type: "unset_from_completion" })
                setGoalMarkedForCompletion(null)
              }}
              size="lg"
              color="white"
              className={` ${
                currentGoalIsMarkedForCompletion ? "icon-appear" : "icon-hide"
              } fas fa-undo`}
            />
            <Icon
              onClick={() => {
                trackClick("goalInteraction", { type: "set_for_delete" })
                setToDelete(true)
              }}
              size="xl"
              className={`${
                toDelete || currentGoalIsMarkedForCompletion
                  ? "icon-hide"
                  : "icon-appear"
              } fas fa-trash`}
            />
            <Icon
              onClick={() => {
                trackClick("goalInteraction", { type: "remove" })
                removeGoal(goal)
              }}
              size="xl"
              className={` ${
                toDelete ? "icon-appear" : "icon-hide"
              } fas fa-check`}
            />
          </IconContainer>
        </div>
      </FirstGoalRow>
      <SecondGoalRow>
        <SmallTitle>{motivation}</SmallTitle>
        <SmallTitle className="d-flex flex-column align-items-center">
          <InlineTitle className="my-0" size="xs" color="transparent">
            started
          </InlineTitle>

          <InlineTitle size="xs" color="semitransparent" className="my-0">
            {getFormattedDate(goal.dateCreated).split(" ").slice(1).join(" ")}
          </InlineTitle>
          {goal.dateCompleted ? (
            <>
              <InlineTitle className="my-0" size="xs" color="transparent">
                finished
              </InlineTitle>

              <InlineTitle size="xs" color={color} className="my-0">
                {getFormattedDate(goal.dateCompleted)
                  .split(" ")
                  .slice(1)
                  .join(" ")}
              </InlineTitle>
            </>
          ) : null}
        </SmallTitle>
      </SecondGoalRow>
    </GoalContainer>
  )
}
