import React, { useCallback, useEffect, useState } from "react"
import {
  completeGoal,
  deleteGoal,
  getGoals,
  revertGoalCompletion,
  saveGoal,
} from "../api/goals"
import { Goal } from "../api/interfaces"
// import { SampleGoals } from "../api/sampleGoals"

interface TopLevelState {
  goalMarkedForCompletion: Goal | null
  setGoalMarkedForCompletion: (goal: Goal | null) => void

  goals: Goal[]
  addGoal: (goal: Goal) => Promise<boolean>
  removeGoal: (goal: Goal) => Promise<void>
  finishGoal: (goal: Goal) => Promise<void>
  revertGoal: (goal: Goal) => Promise<void>

  clearAddGoalError: () => void

  addGoalError: string
  addingGoal: boolean

  removeGoalError: string
  removingGoal: boolean

  completeGoalError: string
  completingGoal: boolean

  firstTime: boolean
  setFirstTime: (firstTime: boolean) => void
}

const dd: TopLevelState = {
  goalMarkedForCompletion: null,
  setGoalMarkedForCompletion: (goal: Goal | null) => {},
  goals: [],
  addGoal: () => Promise.resolve(true),
  removeGoal: () => Promise.resolve(),
  finishGoal: () => Promise.resolve(),
  revertGoal: () => Promise.resolve(),
  addGoalError: "",
  removeGoalError: "",
  completeGoalError: "",

  clearAddGoalError: () => {},

  addingGoal: false,
  removingGoal: false,
  completingGoal: false,

  firstTime: false,
  setFirstTime: (firstTime: boolean) => {},
}

interface Props {
  children: React.ReactNode
}

const DataContext = React.createContext<TopLevelState>(dd)

export const useData = () => React.useContext(DataContext)
export const DataProvider = ({ children }: Props) => {
  const [
    goalMarkedForCompletion,
    setGoalMarkedForCompletion,
  ] = useState<Goal | null>(null)

  const [goals, setGoals] = useState<Goal[]>(dd.goals)
  const [addingGoal, setAddingGoal] = useState<boolean>(dd.addingGoal)
  const [removingGoal, setRemovingGoal] = useState<boolean>(dd.removingGoal)
  const [completingGoal, setCompletingGoal] = useState<boolean>(
    dd.completingGoal
  )
  const [addGoalError, setAddGoalError] = useState<string>(dd.addGoalError)
  const [removeGoalError, setRemoveGoalError] = useState<string>(
    dd.addGoalError
  )

  const [completeGoalError, setCompleteGoalError] = useState<string>(
    dd.addGoalError
  )

  const clearAddGoalError = () => {
    setAddGoalError("")
  }

  const retrieveGoals = useCallback(async () => {
    const goals = await getGoals()
    setGoals(goals)
  }, [])

  const addGoal = async (goal: Goal) => {
    setAddingGoal(true)
    try {
      const goals = await saveGoal(goal)
      setGoals(goals)
      setAddingGoal(false)
      return true
    } catch (err) {
      console.error(err)
      setAddGoalError(err)
      setAddingGoal(false)
      return false
    }
  }

  const removeGoal = async (goal: Goal) => {
    setRemovingGoal(true)
    try {
      const goals = await deleteGoal(goal)
      setGoals(goals)
      setRemovingGoal(false)
    } catch (err) {
      console.error(err)
      setRemoveGoalError(err)
      setRemovingGoal(false)
    }
  }

  const finishGoal = async (goal: Goal) => {
    setCompletingGoal(true)
    try {
      const goals = await completeGoal(goal)
      setGoals(goals)
      setCompletingGoal(false)
    } catch (err) {
      console.error(err)
      setCompleteGoalError(err)
      setCompletingGoal(false)
    }
  }

  const revertGoal = async (goal: Goal) => {
    try {
      const goals = await revertGoalCompletion(goal)
      setGoals(goals)
    } catch (err) {
      console.error(err)
    }
  }

  const [firstTime, _setFirstTime] = useState<boolean>(dd.firstTime)

  const setFirstTime = (firstTime: boolean) => {
    _setFirstTime(firstTime)
    localStorage.setItem("YMW_FIRST_TIME", "visited")
  }

  useEffect(() => {
    const isFirstTime = localStorage.getItem("YMW_FIRST_TIME")
    if (!isFirstTime) setFirstTime(true)
    else setFirstTime(false)
  }, [])

  useEffect(() => {
    retrieveGoals()
  }, [retrieveGoals])
  return (
    <DataContext.Provider
      value={{
        goalMarkedForCompletion,
        setGoalMarkedForCompletion,

        revertGoal,

        addGoal,
        addingGoal,
        addGoalError,

        removeGoal,
        removeGoalError,
        removingGoal,

        finishGoal,
        completingGoal,
        completeGoalError,

        goals,
        firstTime,
        setFirstTime,

        clearAddGoalError,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
