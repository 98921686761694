import { GoalType } from "./api/interfaces"
import { myTheme } from "./theme"
import moment from "moment"

export const getFormattedDate = (date: Date) => {
  return new Date(date).toDateString()
}

export const _getFormattedDate = (date: Date) => {
  var year = date.getFullYear()

  var month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : "0" + month

  var day = date.getDate().toString()
  day = day.length > 1 ? day : "0" + day

  return month + "/" + day + "/" + year
}

export const getDifferenceBetweenDates = (
  firstDate: Date,
  secondDate: Date
) => {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds

  const diffDays = Math.round(
    Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay)
  )
  return diffDays
}

export const getLastDayOfYear = (setForFuture: boolean) => {
  if (setForFuture) {
    return moment().endOf("year").add(1, "year").toDate()
  } else {
    return moment().endOf("year").toDate()
  }
}

export const getLastDayOfMonth = (setForFuture: boolean) => {
  if (setForFuture) {
    return moment().endOf("month").add(1, "month").toDate()
  } else {
    return moment().endOf("month").toDate()
  }
}

export const getLastDayOfWeek = (setForFuture: boolean) => {
  if (setForFuture) {
    return moment().endOf("isoWeek").add(1, "week").toDate()
  } else {
    return moment().endOf("isoWeek").toDate()
  }
}

export const getColorFromType = (
  type?: GoalType
): keyof typeof myTheme["colors"] => {
  switch (type) {
    case "month":
      return "yellow"
    case "year":
      return "cyan"
    case "week":
      return "pink"
    default:
      return "white"
  }
}

export const getDueDateFromType = (type: GoalType, setForFuture: boolean) => {
  switch (type) {
    case "month":
      return getLastDayOfMonth(setForFuture)
    case "year":
      return getLastDayOfYear(setForFuture)
    case "week":
      return getLastDayOfWeek(setForFuture)
  }
}
