import React, { useState } from "react"
import styled from "styled-components/macro"
import { myTheme } from "../theme"
import { trackClick } from "./analytics"
interface StyledButtonProps {
  color: keyof typeof myTheme["colors"]
  jumbo?: boolean
  inactive?: boolean
  clickEventTitle: string
  clickEventProperties: any
}

interface StyledIconButtonProps {
  size: keyof typeof myTheme["fonts"]["sizes"]
  colorPrimary: keyof typeof myTheme["colors"]
  colorSecondary: keyof typeof myTheme["colors"]
  jumbo?: boolean
  inactive?: boolean
}

export const StyledButton: React.FC<
  StyledButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  const [pressed, setPressed] = useState<boolean>(false)
  const { clickEventTitle, clickEventProperties } = props
  return (
    <StyledButtonButton
      {...props}
      onClick={(e) => {
        trackClick(clickEventTitle, clickEventProperties)
        props.onClick && props.onClick(e)
      }}
      onTouchStart={() => setPressed(true)}
      onTouchEnd={() => setPressed(false)}
      className={
        pressed
          ? `pressed ${props.className}`
          : props.inactive
          ? `inactive ${props.className}`
          : props.className
      }
    />
  )
}

export const StyledButtonButton = styled.button<StyledButtonProps>`
  text-decoration: unset !important;
  background: ${(props) => props.theme.colors.mediumbackground};
  /* backdrop-filter: blur(2px); */

  box-shadow: ${(props) => `
  
  0px 17px 10px -10px ${props.theme.colors.transparent};

  `};

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &.clickme {
    animation: flicker 3s linear infinite;

    @keyframes flicker {
      0% {
        border: ${(props) => `solid 4px ${props.theme.colors["pink"]}`};
      }
      33% {
        border: ${(props) => `solid 4px ${props.theme.colors["cyan"]}`};
      }
      66% {
        border: ${(props) => `solid 4px ${props.theme.colors["yellow"]}`};
      }
      100% {
        border: ${(props) => `solid 4px ${props.theme.colors["pink"]}`};
      }
    }
  }

  &.inactive {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  width: 6rem;
  height: 6rem;

  min-width: 6rem;
  min-height: 6rem;

  max-width: 6rem;
  max-height: 6rem;
  border-radius: 3rem;

  transition: all ease-in-out 0.3s;
  &.pressed {
    background: black;
    transition: all ease-in-out 0.3s;
  }

  padding: ${(props) => (props.jumbo ? "1rem 1.5rem" : ".5rem .75rem")};

  color: ${(props) => props.theme.colors[props.color]};
  border: ${(props) => `solid 4px ${props.theme.colors[props.color]}`};

  font-family: ${(props) => props.theme.fonts.family.primary};
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.6px;
  font-size: ${(props) =>
    props.jumbo ? props.theme.fonts.sizes.lg : props.theme.fonts.sizes.md};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  i {
    margin-left: 0.25rem;
  }

  a {
    color: ${(props) => props.theme.colors[props.color]};
    text-decoration: unset;
  }

  &.loading {
    transition: all ease-in-out 0.3s;

    border: unset;

    ::after {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 100%;
      transition: all ease-in-out 0.3s;
      border: ${(props) => `dashed 2px ${props.theme.colors[props.color]}`};
      animation: rotate 20s linear infinite;

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    i {
      animation-name: spinner;
      animation-timing-function: ease-in-out;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-delay: 0s;
      @keyframes spinner {
        0% {
          transform: scale(1);
          opacity: 1;
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(359deg);
        }
      }
    }
  }

  :hover,
  :active {
  }
  :disabled {
    opacity: 0.3;
  }
`

export const StyledIconButton: React.FC<
  StyledIconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  const [pressed, setPressed] = useState<boolean>(false)
  const previousClassName = props.className
  return (
    <StyledIconButtonButton
      {...props}
      onTouchStart={() => setPressed(true)}
      onTouchEnd={() => setPressed(false)}
      className={`${previousClassName} ${
        pressed ? "pressed" : props.inactive ? "inactive" : ""
      }`}
    />
  )
}

export const StyledIconButtonButton = styled.button<StyledIconButtonProps>`
  &.inactive {
    opacity: 0.5;
  }

  transition: all ease-in-out 0.3s;
  &.pressed {
    background: black;
    transition: all ease-in-out 0.3s;
  }

  border-radius: 20px;

  height: ${(props) => props.theme.fonts.sizes[props.size]};
  width: ${(props) => props.theme.fonts.sizes[props.size]};
  max-height: ${(props) => props.theme.fonts.sizes[props.size]};
  max-width: ${(props) => props.theme.fonts.sizes[props.size]};
  min-height: ${(props) => props.theme.fonts.sizes[props.size]};
  min-width: ${(props) => props.theme.fonts.sizes[props.size]};

  background: transparent;
  color: ${(props) => props.theme.colors[props.colorPrimary]};
  font-family: PhosphateSolid;
  letter-spacing: 0.6px;
  font-size: ${(props) =>
    props.jumbo ? props.theme.fonts.sizes.lg : props.theme.fonts.sizes.sm};
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-left: 0.25rem;
  }

  :hover,
  :active {
  }
  :disabled {
    opacity: 0.3;
  }
`
