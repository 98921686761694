import React, { useEffect } from "react"
import "./App.css"

import { Route, Switch } from "react-router-dom"
import { Container, Main } from "./components/Container"
import { RouterLayer } from "./RoutingLayer"

import { Add } from "./pages/Add"
import { View } from "./pages/View"
import { useData } from "./providers/data"
import { StatusLight } from "./PWAProvider/StatusLight"
import { UpdateAppButton } from "./PWAProvider/UpdateAppButton"
import { Welcome } from "./Welcome"

const App: React.FC = () => {
  const { firstTime, goalMarkedForCompletion } = useData()
  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty("--app-height", `${window.innerHeight}px`)
    }
    window.addEventListener("resize", appHeight)
    appHeight()
  }, [])

  if (firstTime) {
    return <Welcome />
  }
  return (
    <>
      <RouterLayer>
        <Container>
          <Main extraDark={!!goalMarkedForCompletion}>
            <Switch>
              <Route path="/add">
                <Add />
              </Route>
              <Route exact path="/">
                <View />
              </Route>
            </Switch>
          </Main>
        </Container>
      </RouterLayer>
      <UpdateAppButton />
      <StatusLight />
    </>
  )
}

export default App
