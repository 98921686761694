import styled from "styled-components/macro"

export const Button = styled.button`
  position: fixed;
  top: 1rem;
  right: 1rem;
  height: 3rem;
  border-radius: 0px;
  margin-top: 1rem;
  padding: 0.5rem;
  background: black;
  border: 2px solid ${(props) => props.theme.colors.cyan};
  color: ${(props) => props.theme.colors.cyan};
  font-family: ${(props) => props.theme.fonts.family.primary};
  font-weight: 800;
  font-size: 1rem;
  animation: border-updater infinite 2s alternate;

  @keyframes border-updater {
    from {
      border-radius: 0px;
    }
    to {
      border-radius: 1.5rem;
    }
  }
`

export const LightIndicator = styled.div<{
  color: string
}>`
  position: fixed;
  right: 3.25rem;
  top: 1.25rem;
  font-family: Raleway, sans-serif;
  font-weight: 800;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;

  background: ${(props) => props.color};
  box-shadow: ${(props) => `0 0 15px 0 ${props.color}`};
  transition: all 0.5s;
  display: flex;
  align-items: center;
  label {
    color: ${(props) => props.color};
    margin-left: 0.65rem;
    font-size: ${(props) => props.theme.fonts.sizes.xxxs};
  }
`
