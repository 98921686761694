import styled from "styled-components/macro"
import { GoalType } from "../api/interfaces"
import { myTheme } from "../theme"
import { getColorFromType } from "../utilities"

type TightnessType = "spaced" | "tight" | "supertight"

interface TextProps {
  size?: keyof typeof myTheme["fonts"]["sizes"]
  textAlign?: "left" | "center" | "right"
  alignSelf?: "flex-start" | "center" | "flex-end"
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
  tightness?: TightnessType
  color?: keyof typeof myTheme["colors"]
  inactive?: boolean
  type?: GoalType
}

const tightnessToSpacingValue = (tightness: TightnessType | undefined) => {
  switch (tightness) {
    case "spaced":
      return "1px"
    case "tight":
      return "-.5px"
    case "supertight":
      return "-1px"
    default:
      return "0px"
  }
}

export const Title = styled.p<TextProps>`
  overflow-wrap: anywhere;
  align-self: ${(props) => props?.alignSelf || ""};
  text-align: ${(props) => props?.textAlign || ""};
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || "lg"]};
  font-weight: ${(props) => props.theme.fonts.weights[props?.weight || 500]};
  color: ${(props) => props.theme.colors[props?.color || "white"]};
  font-family: ${(props) => props.theme.fonts.family.primary};
  transition: color ease-in-out 0.3s;
  margin: 0.25rem 0;
  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};

  &.crossed-out {
    text-decoration: line-through;
    text-decoration-color: ${(props) =>
      props.theme.colors[getColorFromType(props?.type) || "white"]};
  }

  &.tocomplete {
    animation: flickertext 3s linear infinite;
    overflow-wrap: unset;

    @keyframes flickertext {
      0% {
        color: ${(props) => `${props.theme.colors["pink"]}`};
      }
      33% {
        color: ${(props) => `${props.theme.colors["cyan"]}`};
      }
      66% {
        color: ${(props) => `${props.theme.colors["yellow"]}`};
      }
      100% {
        color: ${(props) => `${props.theme.colors["pink"]}`};
      }
    }
  }
`

export const InlineTitle = styled.span<TextProps>`
  align-self: ${(props) => props?.alignSelf || ""};
  text-align: ${(props) => props?.textAlign || ""};
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || "lg"]};
  font-weight: ${(props) => props.theme.fonts.weights[props?.weight || 500]};
  color: ${(props) => props.theme.colors[props?.color || "white"]};
  font-family: ${(props) => props.theme.fonts.family.primary};
  transition: color ease-in-out 0.3s;
  margin: 0.25rem 0;
  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};
  display: inline;
`

export const Icon = styled.i<TextProps>`
  align-self: ${(props) => props?.alignSelf || ""};
  text-align: ${(props) => props?.textAlign || ""};
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || "xxl"]};
  font-weight: ${(props) => props.theme.fonts.weights[props?.weight || 500]};
  color: ${(props) => props.theme.colors[props?.color || "white"]};
  transition: color ease-in-out 0.3s;
  margin: 0.25rem 0;
  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};

  &.icon-default {
    transition: opacity 0.3s, transform 0.3s;
  }

  &.icon-hide {
    transition: opacity 0.3s, transform 0.3s;
    transform: rotate(-180deg) scale(0.5);
    opacity: 0;
    pointer-events: none;
  }

  &.icon-appear {
    transition: opacity 0.3s, transform 0.3s;
    transform: rotate(180) scale(1);
    opacity: 1;
    pointer-events: all;
  }
`

export const SmallTitle = styled.p<TextProps>`
  overflow-wrap: anywhere;
  align-self: ${(props) => props?.alignSelf || ""};
  text-align: ${(props) => props?.textAlign || ""};
  font-size: ${(props) => props.theme.fonts.sizes["sm"]};
  font-weight: ${(props) => props.theme.fonts.weights[props?.weight || 400]};
  color: ${(props) => props.theme.colors[props?.color || "semitransparent"]};
  font-family: ${(props) => props.theme.fonts.family.primary};
  transition: color ease-in-out 0.3s;
  margin: 0.25rem 0;
  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};
`

export const DenseTitle = styled.p<TextProps>`
  align-self: ${(props) => props?.alignSelf || ""};
  text-align: ${(props) => props?.textAlign || ""};
  font-size: ${(props) => props.theme.fonts.sizes["md"]};
  font-weight: ${(props) => props.theme.fonts.weights[props?.weight || 700]};
  color: ${(props) => props.theme.colors[props?.color || "white"]};
  font-family: ${(props) => props.theme.fonts.family.primary};
  transition: color ease-in-out 0.3s;
  margin: 0.25rem 0;
  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};
`
export const Banner = styled.p<TextProps>`
  align-self: ${(props) => props?.alignSelf || ""};
  text-align: ${(props) => props?.textAlign || ""};
  font-size: ${(props) => props.theme.fonts.sizes[props?.size || "xxl"]};
  font-weight: ${(props) => props.theme.fonts.weights[props?.weight || 800]};
  color: ${(props) => props.theme.colors[props?.color || "white"]};
  font-family: ${(props) => props.theme.fonts.family.primary};
  transition: color ease-in-out 0.3s;
  margin: 0.25rem 0;

  line-height: unset;
  letter-spacing: ${(props) => tightnessToSpacingValue(props.tightness)};
`
