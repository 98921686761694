import moment from "moment"
import { Goal } from "./interfaces"

export const getGoals = async (): Promise<Goal[]> => {
  const localStorageString = localStorage.getItem("YEAR_MONTH_WEEK__GOALS")
  if (!localStorageString) return Promise.resolve([])
  try {
    const localStorageJSON: Goal[] = JSON.parse(localStorageString)
    localStorageJSON.forEach((goal) => {
      if (goal.dateCompleted) {
        goal.dateCompleted = new Date(
          Date.parse((goal.dateCompleted as unknown) as string)
        )
      }
      if (goal.dateCompleted) {
        goal.dateCreated = new Date(
          Date.parse((goal.dateCreated as unknown) as string)
        )
      }
    })
    return Promise.resolve(localStorageJSON)
  } catch (e) {
    return Promise.reject(`Bad JSON found in local storage: ${e}`)
  }
}
export const saveGoal = async (goal: Goal) => {
  if (!goal.name) {
    return Promise.reject("Missing name")
  }
  if (!goal.motivation) {
    return Promise.reject("Missing motivation")
  }
  try {
    const existingGoals = await getGoals()
    const isDuplicate = existingGoals.some(
      (g) =>
        g.name === goal.name &&
        g.type === goal.type &&
        moment(g.dueDate).isSame(goal.dueDate)
    )
    if (isDuplicate) return Promise.reject("Duplicate goal being inserted")
    existingGoals.push(goal)
    localStorage.setItem(
      "YEAR_MONTH_WEEK__GOALS",
      JSON.stringify(existingGoals)
    )
    return Promise.resolve(existingGoals)
  } catch (e) {
    return Promise.reject(`Bad JSON found in local storage: ${e}`)
  }
}
export const updateGoal = async (goal: Goal) => {
  try {
    const existingGoals = await getGoals()
    if (!existingGoals || existingGoals.length === undefined)
      return Promise.reject("Could not find goal")
    const goalToUpdateIndex = existingGoals.findIndex(
      (g) => g.name === goal.name && g.type === goal.type
    )
    if (goalToUpdateIndex === -1) return Promise.reject("Could not find goal")
    existingGoals[goalToUpdateIndex] = goal
    localStorage.setItem(
      "YEAR_MONTH_WEEK__GOALS",
      JSON.stringify(existingGoals)
    )
    return Promise.resolve(existingGoals)
  } catch (e) {
    return Promise.reject(`Bad JSON found in local storage: ${e}`)
  }
}

export const revertGoalCompletion = async (goal: Goal) => {
  try {
    const existingGoals = await getGoals()
    if (!existingGoals || existingGoals.length === undefined)
      return Promise.reject("Could not find goal")
    const goalToUpdateIndex = existingGoals.findIndex(
      (g) => g.name === goal.name && g.type === goal.type
    )
    if (goalToUpdateIndex === -1) return Promise.reject("Could not find goal")
    existingGoals[goalToUpdateIndex].dateCompleted = undefined
    localStorage.setItem(
      "YEAR_MONTH_WEEK__GOALS",
      JSON.stringify(existingGoals)
    )
    return Promise.resolve(existingGoals)
  } catch (e) {
    return Promise.reject(`Bad JSON found in local storage: ${e}`)
  }
}

export const completeGoal = async (goal: Goal) => {
  try {
    const existingGoals = await getGoals()
    if (!existingGoals || existingGoals.length === undefined)
      return Promise.reject("Could not find goal")
    const goalToUpdateIndex = existingGoals.findIndex(
      (g) => g.name === goal.name && g.type === goal.type
    )
    if (goalToUpdateIndex === -1) return Promise.reject("Could not find goal")
    existingGoals[goalToUpdateIndex].dateCompleted = new Date()
    localStorage.setItem(
      "YEAR_MONTH_WEEK__GOALS",
      JSON.stringify(existingGoals)
    )
    return Promise.resolve(existingGoals)
  } catch (e) {
    return Promise.reject(`Bad JSON found in local storage: ${e}`)
  }
}
export const deleteGoal = async (goal: Goal) => {
  try {
    const existingGoals = await getGoals()
    if (!existingGoals || existingGoals.length === undefined)
      return Promise.reject("Could not find goal")
    const goalToUpdateIndex = existingGoals.findIndex(
      (g) => g.name === goal.name && g.type === goal.type
    )
    if (goalToUpdateIndex === -1) return Promise.reject("Could not find goal")
    existingGoals.splice(goalToUpdateIndex, 1)
    localStorage.setItem(
      "YEAR_MONTH_WEEK__GOALS",
      JSON.stringify(existingGoals)
    )
    return Promise.resolve(existingGoals)
  } catch (e) {
    return Promise.reject(`Bad JSON found in local storage: ${e}`)
  }
}
