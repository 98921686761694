import React from "react"
import "./App.css"

import { BrowserRouter, Prompt } from "react-router-dom"

export const RouterLayer: React.FC = ({ children }) => {
  return (
    <>
      <BrowserRouter basename="/" forceRefresh={false}>
        <Prompt
          when={true}
          message={(location, action) => {
            // If user has hit backspace, prompt before navigating
            if (action === "POP") {
              // return `Where you going? Back to ${location.pathname}?`
              return true
            }
            // Otherwise, allow navigation freely
            return true
          }}
        />
        {children}
      </BrowserRouter>
    </>
  )
}
