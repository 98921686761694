import styled from "styled-components/macro"
import { myTheme } from "../theme"

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30vw;
`

export const GoalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem 0;
  width: auto;
`

export const FirstGoalRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(100vw - 4rem);
`

export const IconContainer = styled.div`
  position: relative;
  width: 3rem;
  display: flex;
  align-items: center;
  i {
    position: absolute;
    right: 0;
  }
`

export const SecondGoalRow = styled.div`
  display: grid;
  grid-template-columns: 6.5fr 3.5fr;
  align-items: center;
  width: 100%;
  p:first-child {
  }
  p:last-child {
    border-left: ${(props) => `solid 1px ${props.theme.colors.transparent}`};
    color: ${(props) => props.theme.colors.transparent};
    font-size: ${(props) => props.theme.fonts.sizes.xs};
  }
`

export const DaysLeftBit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: center;
`

export const CheckBoxContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  justify-content: center;

  p {
    position: absolute;
    text-transform: uppercase;
    margin: 0;
  }
`

interface CheckBoxProps {
  color: keyof typeof myTheme["colors"]
}
export const CheckBox = styled.input<CheckBoxProps>`
  width: 2rem;
  height: 2rem !important;
  transition: all ease-in-out 0.15s;
  display: inline-block;

  &.tocomplete {
    transition: all ease-in-out 0.15s;
    animation: flicker 3s linear infinite;
    border-radius: 16px;

    display: inline-block;
    width: 7rem;
    /* transform: scaleX(2); */

    @keyframes flicker {
      0% {
        border: ${(props) => `solid 4px ${props.theme.colors["pink"]}`};
      }
      33% {
        border: ${(props) => `solid 4px ${props.theme.colors["cyan"]}`};
      }
      66% {
        border: ${(props) => `solid 4px ${props.theme.colors["yellow"]}`};
      }
      100% {
        border: ${(props) => `solid 4px ${props.theme.colors["pink"]}`};
      }
    }
  }
  border-radius: 50%;

  vertical-align: middle;
  border: 1px solid #ddd;
  transition: all ease-in-out 0.15s;
  border: ${(props) =>
    `4px solid ${props.theme.colors[props?.color || "white"]}`};
  -webkit-appearance: none;

  :focus {
    outline: none;
  }

  :checked {
    transition: all ease-in-out 0.15s;
    background-color: ${(props) =>
      `${props.theme.colors[props?.color || "white"]}`};
  }

  &.loading {
    opacity: 0.5;
    animation-name: glow;
    animation-timing-function: ease-in-out;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 0s;
    @keyframes glow {
      0% {
        background-color: transparent;
      }
      100% {
        background-color: ${(props) =>
          `${props.theme.colors[props?.color || "white"]}`};
      }
    }
  }
`
