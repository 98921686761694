import React from "react"
import { myTheme } from "../../theme"
import { trackClick } from "../analytics"
import { RadioDiv } from "./component"

interface Props {
  label: string
  id: string
  color: keyof typeof myTheme["colors"]
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  clickEventTitle: string
  clickEventProperties: any
}

export const RadioButton: React.FC<Props> = ({
  id,
  label,
  color,
  checked,
  onChange,
  clickEventTitle,
  clickEventProperties,
}) => {
  return (
    <RadioDiv
      onClick={() => trackClick(clickEventTitle, clickEventProperties)}
      color={color}
    >
      <input type="radio" checked={checked} onChange={onChange} id={id} />
      <label htmlFor={id}>{label}</label>
    </RadioDiv>
  )
}
